import React, { useState, useEffect, Fragment, useRef } from "react";
import "./App.css";
import imgx from './topbanner.jpeg'
import mob from './mobbanner.jpeg'
import { Dialog, Transition } from '@headlessui/react'
import arrowz from "./arrow.svg";
import yellow from "./img_about.jpeg";
import emailjs from '@emailjs/browser';
import ic_insta from './ic_insta.png';
import ic_fb from './ic_fb.png';
import ic_tweet from './ic_twitter.png';
import ic_linkedin from './ic_linkedin.png';
import share_fb from './share_fb.png';
import share_ig from './share_ig.png';


export default function App() {


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  let minBannerSize
  let maxBannerSize
  let minFontSize 
  let maxFontSize 
  //resize effect
  if(!isMobile){
    
  minBannerSize ="4.6rem"
  maxBannerSize ="6.5rem"
  minFontSize = 200
  maxFontSize =320

}
else {
  minBannerSize ="3.4rem"
  maxBannerSize ="6.0rem"
  minFontSize = 150
  maxFontSize =210
}
  const [navSize, setnavSize] = useState(maxBannerSize);
  const [logoWidth, setLogoWidth] = useState(maxFontSize);
  const [navVisible, setNavVisible] = useState("hidden");
  const [fontColor, setFontColor] = useState('#ffffff');

  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const [isOpen, setIsOpen] = useState(false)
  const [isSocialOpen, setSocialIsOpen] = useState(false)
  const [iconNumberG, setIconNumber] = useState(-1);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [slogan, setSlogan] = useState('');

  function closeModal() {
    setIsOpen(false)
  }

  function openModal(iconNumberG) {
    setIsOpen(true)
    setIconNumber(iconNumberG)
  }

  function closeSocialModal() {
    setSocialIsOpen(false)
  }

  function openSocialModal() {
    setSocialIsOpen(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(email, name, mobile, slogan, iconNumberG);

    

    closeModal()
    openSocialModal()
  };

  const handleSloganChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.split(/\s+/); // Split text into words using space as delimiter
    if (words.length <= 20) {
      setSlogan(inputText);
    }
  };

  const iconCount = 50;

  const iconArray = Array.from({ length: iconCount }, (_, index) => index + 1);


  const listenScrollEvent = () => {
    window.scrollY > 50 ? setnavSize(minBannerSize) : setnavSize(maxBannerSize);
    window.scrollY > 50 ? setLogoWidth(minFontSize) : setLogoWidth(maxFontSize);
    window.scrollY > 50 ? setNavVisible("visible") : setNavVisible("hidden");
    window.scrollY > 50 ? setFontColor("#255ba9") : setFontColor("#ffffff");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
      window.removeEventListener("resize", handleResize);

    };
  }, []);

  return (
    <div>

      <nav
        style={{
          backgroundColor: "#fff",
          height: navSize,
          transition: "0s",
          visibility: navVisible
        }}
      >
        <div className="flex w-full h-full text-base p-10 md:p-20 justify-between items-center text-black">
          {!isMobile && (
            <div className="font-bold">TILL 14th OCTOBER</div>
          )}
          {!isMobile && (
            <div className="pb-1.5 pt-2 px-5  choose-effect rounded-full text-white text-sm cursor-pointer hover:text-yellow-300" onClick={handleClick}
            >Click to Choose</div>
          )}
        </div>

      </nav>
      <nav
        style={{
          backgroundColor: "transparent",
          height: navSize,
          transition: "0.3s",
          alignItems: "center",
          pointerEvents: "none"
        }}
      >

        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 345 150" width={logoWidth}
          style={{
            width: logoWidth, // Apply the logo width here
            transition: "width 0.5s", // Add a transition for the width property
            fill: fontColor
          }}
        >
         <path class="cls-1" d="M6.9637,33.4735h8.6025L25.1316,54.65V33.4735h9.9268V75.5854H26.4558L16.89,54.4091V75.5854H6.9637Z"/>
         <path class="cls-1" d="M41.073,33.4735H61.5877v8.4224H51.3006v8.2422H61.5877v8.4224H51.3006V67.163H61.5877v8.4224H41.073Z"/>
         <path class="cls-1" d="M73.62,33.4735l4.8125,12.3931,4.752-12.3931H93.4119L84.448,54.53l8.9639,21.0557H83.1844l-4.752-12.3931L73.62,75.5854H63.3924L72.3562,54.53,63.3924,33.4735Z"/>
<path class="cls-1" d="M112.0613,33.4735a10.6821,10.6821,0,0,1,8.3926,3.3995q3.0366,3.3991,3.0381,9.4145V62.7714q0,6.0161-3.0381,9.415a10.68,10.68,0,0,1-8.3926,3.399h-5.4141a10.676,10.676,0,0,1-8.3925-3.399q-3.0381-3.3984-3.0381-9.415V46.2875q0-6.0147,3.0381-9.4145a10.6776,10.6776,0,0,1,8.3925-3.3995Zm-2.8877,8.1216a3.2328,3.2328,0,0,0-2.918,1.2334q-.8115,1.2334-.8115,4.6021V61.6283q0,3.37.8115,4.6021a3.2307,3.2307,0,0,0,2.918,1.2334h.3613a3.2287,3.2287,0,0,0,2.917-1.2334q.813-1.232.8125-4.6021V47.4306q0-3.3684-.8125-4.6021a3.2307,3.2307,0,0,0-2.917-1.2334Z"/>
<path class="cls-1" d="M128.9051,33.4735h8.6025L147.073,54.65V33.4735H157V75.5854h-8.6026l-9.5654-21.1763V75.5854h-9.9267Z"/>
<path class="cls-1" d="M178.6551,33.4735h15.3408a10.6821,10.6821,0,0,1,8.3926,3.3995q3.0366,3.3991,3.038,9.4145v1.3838q0,6.0168-3.038,9.084-3.04,3.0681-8.3926,3.0683h-5.1133V75.5854H178.6551Zm16.5439,12.814a7.5917,7.5917,0,0,0-.8125-4.1509,3.3443,3.3443,0,0,0-2.917-1.143h-2.5869V52.6044H191.47a3.3418,3.3418,0,0,0,2.917-1.1431,7.5869,7.5869,0,0,0,.8125-4.1508Z"/>
<path class="cls-1" d="M215.1121,33.4735h10.6484l10.2871,42.1119H225.8211l-1.2041-6.0162h-8.3614l-1.2031,6.0162H204.825Zm8.0615,28.877-2.707-13.897-2.7676,13.897Z"/>
<path class="cls-1" d="M239.0555,33.4735H249.283V75.5854H239.0555Z"/>
<path class="cls-1" d="M255.2976,33.4735H263.9L273.4656,54.65V33.4735h9.9268V75.5854H274.79l-9.5654-21.1763V75.5854h-9.9268Z"/>
<path class="cls-1" d="M295.1824,41.8959h-8.1816V33.4735h26.5908v8.4224H305.41v33.69H295.1824Z"/>
<path class="cls-1" d="M326.2254,46.4081a3.8135,3.8135,0,0,0,.9619,2.8574,6.87,6.87,0,0,0,2.4062,1.4742q1.4443.5412,3.0987,1.0825a9.4,9.4,0,0,1,3.0986,1.7446,9.0164,9.0164,0,0,1,2.4063,3.4595,16.2927,16.2927,0,0,1,.9619,6.2265,20.04,20.04,0,0,1-.541,4.7525,10.3479,10.3479,0,0,1-1.835,3.91,8.9344,8.9344,0,0,1-3.4893,2.6773,13.5571,13.5571,0,0,1-5.5644.9927h-2.7676q-.7823,0-2.0752-.06-1.295-.0594-2.6475-.15t-2.5263-.1807a13.4434,13.4434,0,0,1-1.7149-.21V66.8022q.6621.0607,1.835.18,1.1733.1217,2.4668.211,1.292.09,2.5566.18,1.2627.09,2.045.09a4.1576,4.1576,0,0,0,3.1591-.9624,4.8159,4.8159,0,0,0,.8721-3.2486,2.905,2.905,0,0,0-.9629-2.4365,9.0457,9.0457,0,0,0-2.4062-1.2934q-1.4444-.5414-3.0987-1.1133a8.8436,8.8436,0,0,1-3.0976-1.895,10.0057,10.0057,0,0,1-2.4063-3.7,17.4213,17.4213,0,0,1-.9629-6.4067,22.1381,22.1381,0,0,1,.542-4.9932,10.8807,10.8807,0,0,1,1.834-4.0908,9.1443,9.1443,0,0,1,3.5195-2.7978,12.9243,12.9243,0,0,1,5.5352-1.0528h2.5869q.7222,0,1.8945.0606t2.4063.15q1.2335.09,2.3164.18a11.0255,11.0255,0,0,1,1.5645.21v8.1821q-.6021-.0592-1.7149-.1806t-2.3164-.21q-1.2041-.0908-2.3457-.1806-1.1441-.09-1.8652-.09a3.389,3.389,0,0,0-2.918,1.1133A6.4386,6.4386,0,0,0,326.2254,46.4081Z"/>
<path class="cls-1" d="M43.5992,90.3012h4.2861v14.4267h9.2v3.8682H43.5992Z"/>
<path class="cls-1" d="M61.4624,108.0346a5.9935,5.9935,0,0,1-2.47-2.3653,7.65,7.65,0,0,1,0-7.0566,5.9939,5.9939,0,0,1,2.47-2.3652,7.9094,7.9094,0,0,1,3.75-.85,7.9907,7.9907,0,0,1,3.7637.85A5.9766,5.9766,0,0,1,71.46,98.6127a7.65,7.65,0,0,1,0,7.0566,5.9763,5.9763,0,0,1-2.4834,2.3653,8.0025,8.0025,0,0,1-3.7637.8486A7.921,7.921,0,0,1,61.4624,108.0346Zm5.9453-3.542a3.7232,3.7232,0,0,0,0-4.7041,2.8354,2.8354,0,0,0-2.1953-.9151,2.8048,2.8048,0,0,0-2.1689.9151,3.727,3.727,0,0,0,0,4.7041,2.8017,2.8017,0,0,0,2.1689.915A2.8323,2.8323,0,0,0,67.4077,104.4926Z"/>
<path class="cls-1" d="M87.4419,108.1391a4.2473,4.2473,0,0,1,.7188,2.4433,4.7979,4.7979,0,0,1-.8887,2.8623,5.626,5.626,0,0,1-2.5088,1.9073A9.847,9.847,0,0,1,81,116.019a9.1358,9.1358,0,0,1-3.6591-.68,5.4417,5.4417,0,0,1-2.418-1.9346,5.0467,5.0467,0,0,1-.85-2.9,5.464,5.464,0,0,1,.0264-.6279h3.9462a2.4134,2.4134,0,0,0,.7188,1.9863A3.1976,3.1976,0,0,0,81,112.5688a3.5214,3.5214,0,0,0,2.1163-.5879,1.8345,1.8345,0,0,0,.8105-1.5557,1.7671,1.7671,0,0,0-.7451-1.5283,3.6075,3.6075,0,0,0-2.13-.5362,8.216,8.216,0,0,1-3.75-.81,5.892,5.892,0,0,1-2.4834-2.2861,6.6086,6.6086,0,0,1-.875-3.4375,6.3134,6.3134,0,0,1,.9014-3.3584,6.1723,6.1723,0,0,1,2.5088-2.3,7.94,7.94,0,0,1,3.6718-.8233,6.78,6.78,0,0,1,2.9278.628l1.5156-1.8819,3.0059,2.169-1.7256,2.0127a5.21,5.21,0,0,1,1.3076,3.5537,5.2658,5.2658,0,0,1-.6934,2.6533,5.6263,5.6263,0,0,1-1.9463,1.999A4.8107,4.8107,0,0,1,87.4419,108.1391ZM83.22,104.0482a3.1206,3.1206,0,0,0-4.3906-4.43,2.9578,2.9578,0,0,0-.8359,2.208,3.014,3.014,0,0,0,.8359,2.209,3.2791,3.2791,0,0,0,4.3906.0126Z"/>
<path class="cls-1" d="M93.3218,108.0346a5.9935,5.9935,0,0,1-2.47-2.3653,7.65,7.65,0,0,1,0-7.0566,5.9939,5.9939,0,0,1,2.47-2.3652,7.9094,7.9094,0,0,1,3.75-.85,7.9907,7.9907,0,0,1,3.7637.85,5.9766,5.9766,0,0,1,2.4834,2.3652,7.65,7.65,0,0,1,0,7.0566,5.9763,5.9763,0,0,1-2.4834,2.3653,8.0025,8.0025,0,0,1-3.7637.8486A7.921,7.921,0,0,1,93.3218,108.0346Zm5.9453-3.542a3.7236,3.7236,0,0,0,0-4.7041,2.8354,2.8354,0,0,0-2.1953-.9151,2.8046,2.8046,0,0,0-2.1689.9151,3.7267,3.7267,0,0,0,0,4.7041,2.8015,2.8015,0,0,0,2.1689.915A2.8323,2.8323,0,0,0,99.2671,104.4926Z"/>
<path class="cls-1" d="M116.7134,107.8119a8.4561,8.4561,0,0,1-3.45-3.3711,10.7795,10.7795,0,0,1,.0264-9.997,8.5459,8.5459,0,0,1,3.4629-3.3721,10.582,10.582,0,0,1,5.07-1.1885,9.5716,9.5716,0,0,1,6.9521,2.8486l-2.64,2.64a5.8765,5.8765,0,0,0-4.208-1.6729,5.8105,5.8105,0,0,0-2.9141.7188,4.9272,4.9272,0,0,0-1.96,2.0254,6.8618,6.8618,0,0,0,0,6.0117,4.9272,4.9272,0,0,0,1.96,2.0254,5.8,5.8,0,0,0,2.9141.7187,6.8281,6.8281,0,0,0,2.5088-.4707,6.0215,6.0215,0,0,0,2.0908-1.3593l2.5088,2.7968a9.9017,9.9017,0,0,1-7.1348,2.8487A10.778,10.778,0,0,1,116.7134,107.8119Z"/>
<path class="cls-1" d="M133.2837,108.0346a5.9935,5.9935,0,0,1-2.47-2.3653,7.65,7.65,0,0,1,0-7.0566,5.9939,5.9939,0,0,1,2.47-2.3652,7.9094,7.9094,0,0,1,3.75-.85,7.9907,7.9907,0,0,1,3.7637.85,5.9766,5.9766,0,0,1,2.4834,2.3652,7.65,7.65,0,0,1,0,7.0566,5.9763,5.9763,0,0,1-2.4834,2.3653,8.0025,8.0025,0,0,1-3.7637.8486A7.921,7.921,0,0,1,133.2837,108.0346Zm5.9453-3.542a3.7236,3.7236,0,0,0,0-4.7041,2.8354,2.8354,0,0,0-2.1953-.9151,2.8048,2.8048,0,0,0-2.1689.9151,3.7267,3.7267,0,0,0,0,4.7041,2.8017,2.8017,0,0,0,2.1689.915A2.8323,2.8323,0,0,0,139.229,104.4926Z"/>
<path class="cls-1" d="M158.2945,96.9652a6.215,6.215,0,0,1,1.4248,4.3389v7.292h-4.1035V101.54a2.85,2.85,0,0,0-.627-1.96,2.1883,2.1883,0,0,0-1.7246-.7061,2.4814,2.4814,0,0,0-1.9346.8106,3.0356,3.0356,0,0,0-.7314,2.1172v6.7949h-4.1035V95.685h3.5546l.2608,1.5156a5.23,5.23,0,0,1,1.8164-1.333,5.7,5.7,0,0,1,2.34-.47A4.918,4.918,0,0,1,158.2945,96.9652Z"/>
<path class="cls-1" d="M171.82,108.1a7.5583,7.5583,0,0,1-3.4239.7578,4.6077,4.6077,0,0,1-3.3457-1.1894,5.1457,5.1457,0,0,1-1.2275-3.8291V98.9516h-2.1953V95.685h2.1953V92.47l4.0771-.4443V95.685h3.2139v3.2666H167.9v4.8877a1.5487,1.5487,0,0,0,.3916,1.163,1.4752,1.4752,0,0,0,1.0713.379,3.284,3.284,0,0,0,1.49-.3663Z"/>
<path class="cls-1" d="M183.4507,96.1947a5.6088,5.6088,0,0,1,2.3652,2.2481,6.7366,6.7366,0,0,1,.8233,3.3838,11.2161,11.2161,0,0,1-.13,1.542H177.022a2.4316,2.4316,0,0,0,1.0713,1.6074,3.9047,3.9047,0,0,0,2.2734.6142,6.2466,6.2466,0,0,0,1.8819-.2871,4.7594,4.7594,0,0,0,1.5683-.8105l2.1436,2.3a7.6159,7.6159,0,0,1-2.6006,1.5683,9.2527,9.2527,0,0,1-3.2022.5489,8.61,8.61,0,0,1-3.8935-.8233,5.9374,5.9374,0,0,1-2.5352-2.3388,7.6058,7.6058,0,0,1-.0136-7.1094,6.093,6.093,0,0,1,2.4443-2.3906,7.505,7.505,0,0,1,3.6328-.85A7.8729,7.8729,0,0,1,183.4507,96.1947Zm-5.5273,3.0713a2.4452,2.4452,0,0,0-.876,1.751h5.6982a2.4,2.4,0,0,0-.8232-1.751,3.0012,3.0012,0,0,0-2-.6279A3.0628,3.0628,0,0,0,177.9234,99.266Z"/>
<path class="cls-1" d="M187.9722,107.2113l1.5674-2.8232a7.8313,7.8313,0,0,0,2.1826.8887,10.0537,10.0537,0,0,0,2.47.3134q1.6992,0,1.6992-.914a.754.754,0,0,0-.4707-.7061,4.48,4.48,0,0,0-1.5683-.3135,6.9606,6.9606,0,0,1-3.9072-1.2285,3.437,3.437,0,0,1-1.45-2.875,3.6,3.6,0,0,1,1.5683-3.0713,7.07,7.07,0,0,1,4.2334-1.1367,11.1491,11.1491,0,0,1,2.8877.3662,8.0675,8.0675,0,0,1,2.3662,1.02l-1.5947,2.7441a6.891,6.891,0,0,0-3.4756-.8369q-1.9878,0-1.9863.9414a.6522.6522,0,0,0,.4043.6006,4.2309,4.2309,0,0,0,1.3721.3135,8.2962,8.2962,0,0,1,4.2216,1.2548,3.4028,3.4028,0,0,1,1.45,2.9278,3.628,3.628,0,0,1-1.5947,3.123,7.498,7.498,0,0,1-4.417,1.1367A10.3357,10.3357,0,0,1,187.9722,107.2113Z"/>
<path class="cls-1" d="M211.3892,108.1a7.5582,7.5582,0,0,1-3.4238.7578,4.6073,4.6073,0,0,1-3.3457-1.1894,5.1453,5.1453,0,0,1-1.2276-3.8291V98.9516h-2.1953V95.685h2.1953V92.47l4.0772-.4443V95.685h3.2138v3.2666h-3.2138v4.8877a1.5487,1.5487,0,0,0,.3916,1.163,1.4749,1.4749,0,0,0,1.0713.379,3.2839,3.2839,0,0,0,1.49-.3663Z"/>
<path class="cls-1" d="M223.399,107.8119a8.4549,8.4549,0,0,1-3.45-3.3711,10.7791,10.7791,0,0,1,.0263-9.997,8.5471,8.5471,0,0,1,3.4629-3.3721,10.5822,10.5822,0,0,1,5.07-1.1885,9.572,9.572,0,0,1,6.9522,2.8486l-2.64,2.64a5.8764,5.8764,0,0,0-4.208-1.6729,5.81,5.81,0,0,0-2.914.7188,4.9274,4.9274,0,0,0-1.96,2.0254,6.8618,6.8618,0,0,0,0,6.0117,4.9274,4.9274,0,0,0,1.96,2.0254,5.8,5.8,0,0,0,2.914.7187,6.8277,6.8277,0,0,0,2.5088-.4707,6.02,6.02,0,0,0,2.0908-1.3593l2.5088,2.7968a9.9015,9.9015,0,0,1-7.1347,2.8487A10.7775,10.7775,0,0,1,223.399,107.8119Z"/>
<path class="cls-1" d="M237.7339,90.3012h4.1035v18.2949h-4.1035Z"/>
<path class="cls-1" d="M247.521,108.0346a5.9935,5.9935,0,0,1-2.47-2.3653,7.65,7.65,0,0,1,0-7.0566,5.9939,5.9939,0,0,1,2.47-2.3652,7.9094,7.9094,0,0,1,3.75-.85,7.9907,7.9907,0,0,1,3.7637.85,5.9766,5.9766,0,0,1,2.4834,2.3652,7.65,7.65,0,0,1,0,7.0566,5.9763,5.9763,0,0,1-2.4834,2.3653,8.0025,8.0025,0,0,1-3.7637.8486A7.921,7.921,0,0,1,247.521,108.0346Zm5.9453-3.542a3.7232,3.7232,0,0,0,0-4.7041,2.8354,2.8354,0,0,0-2.1953-.9151,2.8048,2.8048,0,0,0-2.1689.9151,3.7267,3.7267,0,0,0,0,4.7041,2.8017,2.8017,0,0,0,2.1689.915A2.8323,2.8323,0,0,0,253.4663,104.4926Z"/>
<path class="cls-1" d="M259.6617,107.2113l1.5673-2.8232a7.832,7.832,0,0,0,2.1827.8887,10.0531,10.0531,0,0,0,2.47.3134q1.6992,0,1.6992-.914a.754.754,0,0,0-.4707-.7061,4.4805,4.4805,0,0,0-1.5684-.3135,6.9606,6.9606,0,0,1-3.9072-1.2285,3.437,3.437,0,0,1-1.45-2.875,3.6006,3.6006,0,0,1,1.5684-3.0713,7.07,7.07,0,0,1,4.2334-1.1367,11.15,11.15,0,0,1,2.8877.3662,8.0686,8.0686,0,0,1,2.3662,1.02l-1.5947,2.7441a6.891,6.891,0,0,0-3.4756-.8369q-1.9878,0-1.9864.9414a.6524.6524,0,0,0,.4043.6006,4.2316,4.2316,0,0,0,1.3721.3135,8.2966,8.2966,0,0,1,4.2217,1.2548,3.4028,3.4028,0,0,1,1.45,2.9278,3.6282,3.6282,0,0,1-1.5947,3.123,7.498,7.498,0,0,1-4.417,1.1367A10.3353,10.3353,0,0,1,259.6617,107.2113Z"/>
<path class="cls-1" d="M283.523,96.1947a5.6088,5.6088,0,0,1,2.3652,2.2481,6.7377,6.7377,0,0,1,.8233,3.3838,11.2161,11.2161,0,0,1-.13,1.542h-9.4873a2.4316,2.4316,0,0,0,1.0713,1.6074,3.9047,3.9047,0,0,0,2.2734.6142,6.2459,6.2459,0,0,0,1.8818-.2871,4.76,4.76,0,0,0,1.5684-.8105l2.1435,2.3a7.6154,7.6154,0,0,1-2.6,1.5683,9.2531,9.2531,0,0,1-3.2022.5489,8.61,8.61,0,0,1-3.8935-.8233,5.9374,5.9374,0,0,1-2.5352-2.3388,7.6054,7.6054,0,0,1-.0137-7.1094,6.0939,6.0939,0,0,1,2.4444-2.3906,7.5047,7.5047,0,0,1,3.6328-.85A7.8729,7.8729,0,0,1,283.523,96.1947Zm-5.5274,3.0713a2.4455,2.4455,0,0,0-.8759,1.751h5.6982a2.4,2.4,0,0,0-.8232-1.751,3.0012,3.0012,0,0,0-2-.6279A3.0633,3.0633,0,0,0,277.9956,99.266Z"/>
<path class="cls-1" d="M302.524,90.3012v18.2949h-3.5547l-.2608-1.542a5.5342,5.5342,0,0,1-4.2861,1.8037,6.0456,6.0456,0,0,1-3.2022-.85,5.8272,5.8272,0,0,1-2.1816-2.3789,8.1525,8.1525,0,0,1,0-6.9775,5.8266,5.8266,0,0,1,2.1816-2.3789,6.0538,6.0538,0,0,1,3.2022-.85,5.6867,5.6867,0,0,1,3.998,1.49V90.3012Zm-4.9131,14.2051a3.675,3.675,0,0,0,0-4.73,2.9384,2.9384,0,0,0-2.2217-.9024,2.8779,2.8779,0,0,0-2.209.9151,3.6839,3.6839,0,0,0,0,4.7041,2.8747,2.8747,0,0,0,2.209.915A2.9415,2.9415,0,0,0,297.6109,104.5063Z"/>

        </svg>

      </nav>

      <div className="hero-banner">
        <img
          src={isMobile ? mob : imgx}
          width="100%"
          alt="Hero Banner"
          style={{ objectFit: "cover" }}
        />
      </div>
      <div class="marquee">
        <div class="track">
          <div class="content">Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp;Nexon Paints LOGO CONTEST CLOSED&nbsp;&nbsp;*&nbsp;&nbsp; </div>
        </div>
      </div>

      <div style={{ background: "#f4f4f4" }}>
        <main className="p-4 md:p-10 mx-auto max-w-7xl arial-font">
          {!isMobile && (

            <div ref={ref} className="flex flex-col lg:flex-row  lg:my-4">
              <div className="lg:w-1/3  items-center flex flex-row lg:border-r lg:border-solid lg:border-black justify-center m-4">
                <div className="text-4xl font-extralight text-black">01</div>
                <div className="text-lg ml-3 font-medium leading-5">Click to choose our<br />new logo</div>
              </div>
              <div className="lg:w-1/3  items-center flex flex-row lg:border-r lg:border-solid lg:border-black  justify-center m-4">
                <div className="text-4xl font-extralight text-black">02</div>
                <div className="text-lg ml-3 font-medium leading-5">Fill out your<br />details</div>
              </div>
              <div className="lg:w-1/3  items-center flex flex-row justify-center m-4">
                <div className="text-4xl font-extralight text-black">03</div>
                <div className="text-lg ml-3 font-medium leading-5">Click on the submit<br />and share button</div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className="text-sm text-center">
              <div className="ml-3 font-medium leading-5">1. Click to choose our new logo</div>
              <div className="ml-3 font-medium leading-5">2. Fill out your details</div>
              <div className="ml-3 font-medium leading-5">3. Click on the submit and share button</div>

            </div>
          )}
        </main >
      </div>
      <main className="p-4 md:p-10 mx-auto max-w-7xl arial-font">
        <div style={{ position: "absolute", right: "10%" }}>
        </div>
        <p className=" font-semibold text-center text-lg mb-4 mt-2 md:text-3xl custom-blue-color lg:mb-10 "
        >
          Click on any one New Logo option
        </p>
        <div className="icon-grid">
          {iconArray.map((iconNumber) => (
            <div className="icon-container" key={iconNumber}>
              <div className="icon hover:animate-spin delay-1000 ease-in-out duration-1000"
              >
                <img
                  src={require(`./icons/pixel-${iconNumber}.png`)} 
                  alt={`Icon ${iconNumber}`}
                />
              </div>
              <p className='mt-1 text-xs text-gray-500 text-center arial-font'>{iconNumber}</p>
            </div>
          ))}
        </div>
      </main>
      <div className="custom-blue-bg pb-4 md:pb-0">
        <main className="p-4 md:p-10 mx-auto max-w-7xl ">

          <div className="flex flex-row justify-between">
            <div className="text-7xl pt-12 mx-2 lg:mx-0  text-white font-medium"
              style={{ fontSize: "40px" }}>

              About Us
            </div>
            <img src={arrowz} className="mt-4  md:mt-0 w-32 animate-spin slow-spin" alt="spin img" />
          </div>
          <div className="flex flex-col lg:flex-row mt-6 md:mt-10">
            {!isMobile && (
              <div className="mx-2 lg:mx-0  lg:w-1/5  ">
                <p className="font-semibold text-white">
                  AT Nexon Paints
                </p>
                <p className="font-medium text-yellow-300"
                  style={{ fontSize: "15px" }}>
                  We Aspire to Inspire
                </p>
                <p className="font-semibold mt-4 text-white">
                  IN the industry
                </p>
                <p className="font-medium text-yellow-300" style={{ fontSize: "15px" }}>
                  Since 2008
                </p>
                <p className="font-semibold mt-4 text-white">
                  WE are now
                </p>
                <p className=" text-yellow-300 font-medium" style={{ fontSize: "15px" }}>
                  Rebranding ourselves
                </p>
              </div>
            )}

            <div className="lg:w-4/5 text-sm md:text-2xl font-medium text-justify mx-2 text-white">
            Since 2008, Nexon Paints has been a trusted name in coatings, known for innovation and excellence. Our range of paints and textures has evolved with contemporary colors and craftsmanship.
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}

              <div className="flex flex-col text-sm md:text-base lg:flex-row  md:py-4" >
                <div className="lg:w-1/2 md:mr-4">
                We are rebranding ourselves, and starting with a new logo, we aim to inspire and uplift lives, going beyond just painting walls. We aspire to inspire.
As consumers, you are the biggest contributor to the success of Nexon Paints. In building a stronger and lasting relationship, we at Nexon Paints believe that you should be a part of our rebranding journey.
Nexon Paints is hosting a logo contest from 9th October to 14th October 2023, guess their new logo for a chance to win a home makeover. One winner per state to be chosen based on the correct logo guess with a good slogan. Applicable states are Andhra Pradesh, Telangana, Tamil Nadu, Kerala, Karnataka, Odisha and Maharashtra, (T&C apply).
                </div>
                {/* <div className="lg:w-1/2 ml-4"> */}


                {/* </div> */}
                <img className="lg:w-1/2 md:ml-4 mt-4 md:mt-0 " src={yellow} alt="yellow">
                </img>
              </div>
            </div>
          </div>
        </main>
      </div>

      <main className="px-4 md:px-10 mx-auto  max-w-7xl custom-blue-color  ">
        <div className=" font-medium text-76xl pt-2 mt-7 md:mt-14 text-3xl md:text-4xl">
          Terms and Conditions
        </div>
      </main>
      {/* <main className="p-4 md:p-10 mx-auto max-w-7xl arial-font"> */}

      <main className=" p-6 md:pt-10 md:p-10 mx-auto max-w-7xl text-black text-sm md:text-base text-justify " style={{ fontWeight: 500 }}>
        <div className="flex flex-col lg:flex-row ">
          <div className="lg:w-1/2 md:pr-6">
            <p>
              1. This is a web-based contest conducted by Nexon Paints Private Limited, Hyderabad, Telangana.
            </p>
            <br />
            <p>
              2. This Contest is open to all citizens of the Republic of India, aged 18 years or above.
            </p><br />
            <p>
              3. This contest is only open to the citizens of the states Andhra Pradesh, Telangana, Tamil Nadu, Kerala, Karnataka, Odisha and Maharashtra. (T&C apply).
            </p><br />
            <p>
              4. The Contest will commence from 9th October 2023 to 14th October 2023. The contest will be closed at 11:59 PM on 14th October 2023.
            </p><br />
            <p>
              5. The opening and closing dates and times for entries are as indicated in the contest information. Any entries received before the opening and after the closing of the contest will be invalid for the contest.
            </p><br />
            <p>
              6. Five (5) winners will be selected across all the states above-mentioned, for the contest.
            </p>
            <br />
            <p>
              7. Nexon Paints logo contest winners will be announced after 15th October 2023. The winner list will be announced on social media platforms within 7 days of the end of the contest.
            </p><br />
            <p>
              8. The decision of Nexon Paints Private Limited will be final in choosing the winners and this decision is not disputable. This decision cannot be disputed and no correspondence will be entered into.
            </p><br />
            <p>
              9. Each contestant is only allowed one entry. Multiple entries from the contestants will lead to disqualification.
            </p><br />
            <p>
              10. Nexon Paints Private Limited may, at its sole discretion, may extend or reduce the duration of the contest or amend the contest, contest information, or the terms and conditions for the contest at any time without prior notice.
            </p><br />
          </div>

          <div className="lg:w-1/2 md:pl-6">
            <p>
              11. If more than one participant chooses the correct logo, the contestant with the best slogan will be declared as the winner of the contest.
            </p>
            <br />
            <p>
              12. Any entries which are incomplete, incorrect, or not received by Nexon Paints will be void.
            </p><br />
            <p>
              13. The prize for the contest is a package of premium emulsion paints for your home (maximum of paintable area of 2500 sq. ft only), if the property is within the locations listed.
            </p><br />
            <p>
              14. Nexon Paints Private Limited does not provide painting services to the winners. Nexon Paints Private Limited will only provide the paints needed for the home makeover. The cost of the painting services will be borne by the consumer.
            </p><br />
            <p>
              15. Any candidate entering the contest with their property outside of the listed locations will not be selected for the final review.
            </p><br />
            <p>
              16. Nexon Paints Private Limited reserves the right, in its sole discretion, to use any Contest participant's name, age, photograph and place of birth or residence for the purpose of publicity, advertising, marketing or promotion without prior compensation or notice.
            </p><br />
            <p>
              17. Every Contestant agrees to the terms and conditions authorizing Nexon Paints Private Limited to use the personal information submitted in the entry form like Name, Email ID, and contact number for marketing or promotional purposes.
            </p><br />
            <p>
              18. Any dispute arising under these terms and conditions shall be subject to the jurisdiction of the courts of Hyderabad, Telangana.
            </p><br />
            <p>
              19. Employees of Nexon Paints Private Limited and Members of any organization involved in the organisation, management, promotion, or administration of the contest and their immediate family members are not permitted to enter the contest.
            </p>
          </div>
        </div>
      </main>
      <footer className="custom-blue-bg shadow  ">
        <div className="w-full mx-auto max-w-screen-xl p-5 flex-col md:flex-row flex items-center justify-between">
          {!isMobile && (
          <span className="text-sm text-white sm:text-center ">
            © 2023 <a href="https://nexonpaints.com/" target="_blank" rel="noopener noreferrer" className="hover:underline">NEXON PAINTS</a>
          </span> 
          ) }
          
          <span className="text-sm text-white text-center " style={{ whiteSpace: 'nowrap' }}>
            For details contact - care@nexonpaints.com
            <br/>
            <span className="bg-center  sm:text-center">
            <a href="https://nexonpaints.com/" target="_blank" rel="noopener noreferrer" className="hover:underline cursor-pointer">www.nexonpaints.com</a>
            </span>
          </span>
          <span className="flex flex-row space-x-3 items-baseline md:items-center justify-between mt-2 md:mt-0 ">
            <a href="https://www.instagram.com/nexonpaints/" target="_blank" rel="noopener noreferrer">
              <img src={ic_insta} className="w-4" alt="White Insta Icon" />
            </a>
            <a href="https://www.facebook.com/NexonPaintsVibrantIdeas" target="_blank" rel="noopener noreferrer">
              <img src={ic_fb} className="w-4" alt="White Facebook Icon" />
            </a>

            <a href="https://twitter.com/NexonPaints" target="_blank" rel="noopener noreferrer">
              <img src={ic_tweet}  className="w-4" alt="White Twitter Icon" />
            </a>
            <a href="https://www.linkedin.com/company/nexon-paints/" target="_blank" rel="noopener noreferrer">
              <img src={ic_linkedin} className="w-4 mr-8" alt="White Linkedin Icon" />
            </a>
            {isMobile && (
          <span className="text-sm text-white sm:text-center ">
            © 2023 <a href="https://nexonpaints.com/" target="_blank" rel="noopener noreferrer" className="hover:underline">NEXON PAINTS</a>
          </span> 
          ) }
          </span>
        </div>
      </footer>


      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-bold leading-6 text-blue-600 text-center "
                    >
                      Contest Entry Form
                    </Dialog.Title>
                    <button
                      type="button"
                      className="text-blue-600 bg-yellow-300 focus:outline-none hover:text-yellow-300 hover:bg-blue-600 rounded-2xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                      data-modal-hide="defaultModal"
                      onClick={closeModal}
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>

                  <div className="mt-2">

                  </div>
                  <form className="space-y-3" onSubmit={handleSubmit}>
                    <div>
                      <p className='text-sm font-bold animate-bounce text-center text-gray-700'> Your Choice: {iconNumberG}</p>
                      <label htmlFor="name" className="block mb-1 text-sm font-medium text-gray-900">
                        Your name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900">
                        Your email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="name@company.com"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900">
                        Your Mobile
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="+91 9876543210"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="slogan" className="block mb-1 text-sm font-medium text-gray-900">
                        Your Slogan <span className="text-xs text-gray-500">(20 words max)</span>
                      </label>
                      <textarea
                        id="slogan"
                        rows="2"
                        type="text"
                        name="slogan"
                        value={slogan}
                        onChange={handleSloganChange}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Write a slogan for nexon paints . ."
                        required
                      />
                    </div>
                    <div className="flex justify-between">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="remember"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                            required
                          />
                        </div>
                        <label htmlFor="remember" className="ml-2 text-sm font-medium text-gray-900">
                          I agree to the terms and conditions of the Nexon Paints Logo Contest
                        </label>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full font-bold text-blue-600 bg-yellow-300  hover:bg-blue-600 hover:text-yellow-300 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 text-center"
                    // style={{color
                    //   :"#3351a3"}
                    // }
                    >
                      Submit & Share
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* new popup */}
      <Transition appear show={isSocialOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeSocialModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                <div className=" text-sm text-green-600 text-center">
                    Your guess has been submitted successfully!
                    </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold text-blue-600 text-center mt-2"
                  >
                    Share 
                  </Dialog.Title>
                  <div className="">
                    <div className="flex flex-row space-x-7 items-center justify-center px-10 pt-2">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=https://newlogo.nexonpaints.com" target="_blank" rel="noopener noreferrer">
                        <img src={share_fb} width={40} alt="Share FB Icon" />
                      </a>
                      <a href="https://www.instagram.com/nexonpaints/" target="_blank" rel="noopener noreferrer" >
                        <img src={share_ig} width={40} alt="Share Instagram Icon" />
                      </a>
                    </div>
                  </div>




                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
